import React, { useEffect, useState } from 'react';
import { ref, get } from 'firebase/database';
import { database } from '../firebase';
import { useNavigate } from 'react-router-dom';
import './Hospedajes.css';
import ShimmerCard from '../ShimmerCard';
import Player from 'react-lottie-player'; // Importar Player de react-lottie-player
import animationData from '../animations/gosh.json'; // Ruta al archivo JSON de la animación

function Events() {
  const [hospedajes, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const fetchEvents = async () => {
      try {
        const hospedajesRef = ref(database, 'events');
        const snapshot = await get(hospedajesRef);
        if (snapshot.exists()) {
          const allEvents = Object.keys(snapshot.val()).map(key => ({
            id: key,
            ...snapshot.val()[key]
          }));
          const currentDate = Date.now() / 1000;
          const upcomingEvents = Object.values(allEvents).filter(event =>
            event.dateList.some(dateItem => dateItem.date > currentDate)
          );
          setEvents(upcomingEvents);

        }
      } catch (error) {
        console.error("Error al recuperar los hospedajes:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  const handleCardClick = (events) => {
    navigate(`/evento/${events.name}`, { state: events });
  };

  console.log(animationData);

  return (
    <div className="hospedajes-container">
      {loading ? (
        Array.from({ length: 8 }).map((_, index) => <ShimmerCard key={index} />)
      ) : hospedajes.length > 0 ? (
        hospedajes.map((events) => (
          <div key={events.id} className="card" onClick={() => handleCardClick(events)}>
            <img src={events.flyer} alt={events.name} className="card-logo" />
            <h3 className="card-name">{events.name}</h3>
          </div>
        ))
      ) : (
        <div className="no-events-container">
        <Player
          loop
          autoPlay
          src={animationData} // Archivo JSON de la animación
          style={{ height: '300px', width: '300px' }}
        />
        <h3>No hay eventos disponibles por el momento.</h3>
      </div>
    )}
    </div>
  );
}


export default Events;
