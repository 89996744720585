import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import "./SearchBar.css";
import { Typography } from "@mui/material";

function SearchBar({ placeholder, data, searchTerm, onSearchChange }) {
    const [filteredData, setFilteredData] = useState([]);
    const [wordEntered, setWordEntered] = useState(searchTerm || "");
    const navigate = useNavigate(); // Importar useNavigate

    useEffect(() => {
        setWordEntered(searchTerm || ""); // Asegúrate de que sea una cadena
    }, [searchTerm]);

    useEffect(() => {
        if (wordEntered) handleFilter(wordEntered); // Llama a handleFilter solo si hay un término de búsqueda
    }, [wordEntered]);

    const handleFilter = (searchTerm) => {
        // Asegúrate de que `searchTerm` sea una cadena
        const searchQuery = typeof searchTerm === "string" ? searchTerm.trim().toLowerCase() : "";

        if (!searchQuery) {
            setFilteredData([]); // Limpia si el término de búsqueda está vacío
            return;
        }

        const filtered = data.filter(market => {
            const queries = market.queryes ? market.queryes.toLowerCase() : "";
            return queries.includes(searchQuery);
        });

        setFilteredData(filtered);
        onSearchChange(searchTerm);
    };

    const handleInputChange = (event) => {
        const newSearchTerm = event.target.value;
        setWordEntered(newSearchTerm);
        onSearchChange(newSearchTerm);
    };

    const clearInput = () => {
        setWordEntered("");
        onSearchChange("");
        setFilteredData([]); // Asegúrate de limpiar los resultados
    };


    return (
        <div className="search">
            <div className="searchInputs">
                <input
                    type="text"
                    placeholder={placeholder}
                    value={wordEntered}
                    onChange={handleInputChange}
                />
                <div className="searchIcon">
                    {wordEntered.length === 0 ? (
                        <span>🔍</span>
                    ) : (
                        <span onClick={clearInput}>❌</span>
                    )}
                </div>
            </div>

            {filteredData.length !== 0 && (
                <div className="dataResult">
                    {filteredData.slice(0, 15).map((value, key) => {
                        const today = new Date().getDay(); // Obtiene el día actual (0 para domingo)
                        const todaySchedule = value.scheduleDays?.[today]?.day || "Cerrado";
                        const [morning, afternoon] = todaySchedule.split("|");

                        const handleItemClick = () => {
                            navigate(`/comercio/${value.name}`, { state: value }); // Navega a la ruta y pasa los datos
                        };

                        return (
                            <div key={key} className="dataItem" style={{ cursor: 'pointer' }}>
    {/* Contenedor para logo y nombre */}
    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
        {/* Imagen circular del logo */}
        <img
            src={value.logo}
            alt={`${value.name} Logo`}
            style={{
                width: 80,    // Ajusta el tamaño de la imagen
                height: 60,   // Ajusta el tamaño de la imagen
                borderRadius: '10%',  // Hace la imagen circular
                marginRight: 10, // Espacio entre la imagen y el nombre
            }}
        />

        <Typography>
            <p className="marketName" style={{ display: 'inline', margin: 0 }}>{value.name}</p>
        </Typography>
    </div>

    {/* Sección de horarios de atención */}
    {todaySchedule === "Cerrado" ? (
        <p className="marketSchedule" style={{ color: 'red', marginBottom: 10 }}>Cerrado</p>
    ) : (
        <div className="marketSchedule" style={{ marginBottom: 10 }}>
            <Typography>Horarios de atención</Typography>
            <p>{morning}</p>
            <p>{afternoon}</p>
        </div>
    )}

    {/* Botón de acción */}
    <button 
         style={{ paddingBottom: '10px' }}  // Aquí agregamos el padding-bottom
        onClick={handleItemClick} 
        className="viewDetailsButton">
        Ver más
    </button>
</div>
                        );
                    })}
                </div>
            )}
        </div>
    );
}

export default SearchBar;