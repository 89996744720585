import DisponibilidadModal from './DisponibilidadModal'; // Asegúrate de que la ruta sea correcta
import { Box, Card, CardContent, Typography, IconButton, Stack } from '@mui/material';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; // Importa el CSS principal de Swiper
import 'swiper/css/pagination'; // Importa el CSS específico para la paginación
import { Pagination } from 'swiper/modules'; // Importa el módulo de paginación
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import WifiIcon from '@mui/icons-material/Wifi';
import AcUnitIcon from '@mui/icons-material/AcUnit';
import KitchenIcon from '@mui/icons-material/Kitchen';
import PetsIcon from '@mui/icons-material/Pets';
import BreakfastDiningIcon from '@mui/icons-material/BreakfastDining';
import LocalParkingIcon from '@mui/icons-material/LocalParking';
import PoolIcon from '@mui/icons-material/Pool';
import LandscapeIcon from '@mui/icons-material/Landscape';
import { useLocation } from 'react-router-dom';

function HotelDetails() {
    const location = useLocation();
    const hospedaje = location.state;
    const { name, description, address, phone, attentionSchedule, imageList, services, mapsLocation } = hospedaje;

    // Función para copiar el teléfono al portapapeles
    const copyToClipboard = () => {
        navigator.clipboard.writeText(phone);
    };


    // Mapeo de servicios a íconos y nombres en español
    const serviceIcons = {
        wifi: { icon: <WifiIcon />, name: "Wi-Fi" },
        "air-conditioning": { icon: <AcUnitIcon />, name: "Aire acondicionado" },
        kitchen: { icon: <KitchenIcon />, name: "Cocina" },
        garden: { icon: <LandscapeIcon />, name: "Jardín" },
        "pets-allowed": { icon: <PetsIcon />, name: "Se admiten mascotas" },
        "breakfast-included": { icon: <BreakfastDiningIcon />, name: "Desayuno incluido" },
        parking: { icon: <LocalParkingIcon />, name: "Estacionamiento" },
        pool: { icon: <PoolIcon />, name: "Piscina" }
    };


    return (
        <Box padding={1}>
            {/* Carousel de imágenes */}
            <Swiper
                spaceBetween={10}
                slidesPerView={1}
                pagination={{ clickable: true }} // Activa los dots de paginación
                modules={[Pagination]} // Agrega el módulo de paginación
                style={{ paddingBottom: '2px' }} // Espacio para los dots
            >
                {imageList
                    .filter(image => image.photo && image.photo.trim() !== "")
                    .map((image, index) => (
                        <SwiperSlide key={index}>
                            <Box
                                component="img"
                                src={image.photo}
                                alt={`Image ${index + 1}`}
                                sx={{ width: '100%', height: 250, objectFit: 'cover' }}
                            />
                        </SwiperSlide>
                    ))}
            </Swiper>


            {/* Card de información */}
            <Card sx={{ mt: 2, mb: 2 }}>
                <CardContent>
                    <Typography variant="h5" fontWeight="bold" sx={{ mb: 2 }}>{name}</Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>{description}</Typography>

                    <Typography variant="subtitle1" fontWeight="bold">Horarios</Typography>
                    <Typography variant="body2">Check-in: <strong>a partir de las {attentionSchedule.checkIn}</strong></Typography>
                    <Typography variant="body2">Check-out: <strong>hasta las {attentionSchedule.checkOut}</strong></Typography>

                    <Typography variant="subtitle1" fontWeight="bold" sx={{ mt: 2 }}>Dirección</Typography>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="body2">{address}</Typography>
                        <IconButton
                            color="primary"
                            href={`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(mapsLocation)}`}
                            target="_blank"
                        >
                            <LocationOnIcon />
                        </IconButton>
                    </Stack>

                    <Typography variant="subtitle1" fontWeight="bold" sx={{ mt: 2 }}>Teléfono</Typography>
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="body2">{phone}</Typography>
                        <IconButton color="primary" onClick={copyToClipboard}>
                            <ContentCopyIcon />
                        </IconButton>
                    </Stack>
                </CardContent>
            </Card>

            {/* Servicios */}
            <Typography variant="subtitle1" fontWeight="bold">Servicios</Typography>
            <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', mb: 4 }}>
                {services.map((service, index) => (
                    <Box
                        key={index}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            border: '1px solid #ddd',
                            borderRadius: 2,
                            padding: 1,
                            margin: 0.5
                        }}
                    >
                        {serviceIcons[service.type]?.icon}
                        <Typography variant="body2" sx={{ ml: 1 }}>
                            {serviceIcons[service.type]?.name || service.type.replace('-', ' ')}
                        </Typography>
                    </Box>
                ))}
            </Stack>

            {/* Botón de disponibilidad */}
            <DisponibilidadModal phone ={phone}/>
        </Box>
    );
};

export default HotelDetails;