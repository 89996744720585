import React, { useEffect, useState } from 'react';
import { Snackbar, Button, Alert } from '@mui/material';

const isAndroidDevice = () => /Android/i.test(navigator.userAgent);

const AppPromotionPopup = () => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (isAndroidDevice()) {
            setOpen(true);
        }
    }, []);

    const handleClose = () => setOpen(false);

    return (
        <Snackbar
            open={open}
            autoHideDuration={6000} // Ocultar automáticamente después de 6 segundos
            onClose={handleClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} // Posición del snackbar
        >
            <Alert 
                onClose={handleClose} 
                severity="info" 
                sx={{ display: 'flex', alignItems: 'center' }}
                action={
                    <Button 
                        color="inherit" 
                        size="small" 
                        href="https://play.google.com/store/apps/details?id=com.alvaroegimenez.parquetecnologico&pcampaignid=web_share"
                        target="_blank"
                    >
                        Descargar
                    </Button>
                }
            >
                ¡Descarga nuestra app en Play Store!
            </Alert>
        </Snackbar>
    );
};

export default AppPromotionPopup;
