import React, { useState } from 'react';
import './HeaderImage.css';
import { IconButton, Drawer, List, ListItem, ListItemText, ListItemIcon, Button, Typography, Box } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import ShareIcon from '@mui/icons-material/Share';
import AddIcon from '@mui/icons-material/Add';
import LoginIcon from '@mui/icons-material/Lock';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuthState } from 'react-firebase-hooks/auth';
import { getAuth, signOut } from 'firebase/auth';
import { auth } from './firebase';



const HeaderImage = ({ imageUrl, appName, autor, socialMediaUser, socialMediaUrl }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [user] = useAuthState(auth);
  const navigate = useNavigate();

  

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  // Manejo del gesto de deslizar para cerrar el Drawer
  const handleTouchStart = (event) => {
    // Puedes guardar la posición inicial del toque aquí si es necesario
  };

  const handleTouchEnd = (event) => {
    const touchEndX = event.changedTouches[0].clientX;

    // Cerrar el Drawer si se desliza hacia la izquierda
    if (touchEndX < 50) {
      setDrawerOpen(false);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error) {
      console.error('Error al cerrar sesión:', error);
    }
  };

  
  const handleJoinClick = () => {
    // Abre el enlace en una nueva pestaña
    window.open("https://forms.gle/MVFvMbQamqmv2HS49", "_blank");
  };
  
    const handleShareClick = async () => {
      if (navigator.share) {
        try {
          await navigator.share({
            title: "Jardinfo",
            text: "Únete a Jardinfo y comparte la experiencia.",
            url: "https://jardinfo.app",
          });
          alert("¡Gracias por compartir!");
        } catch (error) {
          if (error.name !== "AbortError") {
            console.error("Error al compartir:", error);
            alert("Ocurrió un error al intentar compartir.");
          } else {
            console.log("El usuario canceló la acción de compartir.");
          }
        }
      } else {
        alert("Compartir no es compatible en este dispositivo.");
      }
    };

  return (
    <div className="header-image-container">
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={() => setDrawerOpen(true)}
        className="icon-button"
        style={{
          position: 'absolute',
          top: '16px',
          left: '20px',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          color: 'white',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
        }}
      >
        <MenuIcon />
      </IconButton>

      <img src={imageUrl} alt="Header" className="header-image" />

      <div className="header-content">
        <h1 className="app-name">{appName}</h1>
        {autor && socialMediaUser && (
          <div className="social-media-info">
            <p className="author-name">{autor}</p>
            <a href={socialMediaUrl} target="_blank" rel="noopener noreferrer" className="social-link">
              {socialMediaUser}
            </a>
          </div>
        )}
      </div>

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        PaperProps={{
          onTouchStart: handleTouchStart,
          onTouchEnd: handleTouchEnd,
          sx: { display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }
        }}
      >
        <Box sx={{ width: 250, paddingTop: '16px' }}>
      <List>
        <ListItem button onClick={handleJoinClick}>
          <ListItemIcon>
            <AddIcon />
          </ListItemIcon>
          <ListItemText primary="Sumarme a Jardinfo" />
        </ListItem>
        <ListItem button onClick={handleShareClick}>
          <ListItemIcon>
            <ShareIcon />
          </ListItemIcon>
          <ListItemText primary="Compartir" />
        </ListItem>
      </List>
    </Box>

        <Box sx={{ p: 2 }}>
          {user && !user.isAnonymous ? (
            <>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => navigate('/comercio')}
                sx={{ maxWidth: '200px', mx: 'auto', mb: 2 }}
              >
                Mi Comercio
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                endIcon={<LogoutIcon />}
                onClick={handleLogout}
                sx={{ maxWidth: '200px', mx: 'auto' }}
              >
                Cerrar Sesión
              </Button>
            </>
          ) : (
            <Button
              variant="contained"
              color="primary"
              endIcon={<LoginIcon />}
              sx={{ maxWidth: '200px', mx: 'auto' }}
              onClick={() => {
                navigate('/login');
                toggleDrawer(false)();
              }}
            >
              Ingresar
            </Button>
          )}
        </Box>
      </Drawer>
    </div>
  );
};

export default HeaderImage;
